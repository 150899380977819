<template>
  <el-dialog v-model="payMemberVisible"
             destroy-on-close
             title="在线支付"
             width="800px">
    <el-form size="default"
             :disabled="form.isConfirm"
             :model="numberValidateForm">
      <el-form-item label="会员等级：">
        <el-radio-group v-model="form.memberLevelId"
                        @change="changeSelect">
          <el-radio border
                    v-for="item in memberList"
                    :key="item.id"
                    :label="item.id"
                    :value="item.id"
                    style="margin-bottom: 10px">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="抵扣金额："
                        v-if="form.offsetMoney && form.offsetMoney > 0">
            <span class="price">{{ form.offsetMoney }}</span>
          </el-form-item>
          <el-form-item label="支付金额：">
            <span class="price">{{ form.money }}</span>
          </el-form-item>
          <el-form-item label="支付方式：">
            <el-radio-group v-model="form.payType"
                            class="pay-radio">
              <div class="radio-wrapper">
                <el-radio label="wxpay"><img class="pay-icon"
                       src="@/static/Pay/wxpay_logo.png"
                       alt="" /></el-radio>
              </div>
              <div class="radio-wrapper">
                <el-radio label="alipay"><img class="pay-icon"
                       src="@/static/Pay/alipay_logo.png"
                       alt="" /></el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12"
                style="text-align: center"
                v-if="codeUrl">
          <img :src="codeUrl"
               class="qrcode" />
          <p>请打开手机支付软件扫一扫付款</p>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary"
                   size="default"
                   @click="confirmOrder"
                   :disabled="form.isConfirm">
          确认下单
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { storeToRefs } from "pinia";
import { Member } from "@/store";
import { reactive, ref, toRefs, computed } from "vue";
import { pay, checkOrderStatus } from "@/api/Member/Member";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  setup() {
    const currentMember = computed(() => storeToRefs(Member()).currentMember);
    const memberList = computed(() => storeToRefs(Member()).memberList);
    const payMemberVisible = computed(
      () => storeToRefs(Member()).payMemberVisible
    );
    const orderNo = computed(() => storeToRefs(Member()).orderNo);
    const timer = computed(() => storeToRefs(Member()).timer);

    const codeUrl = ref("");
    const form = ref({
      memberLevelId: null,
      level: null,
      originalMoney: null,
      offsetMoney: null,
      money: null,
      payType: null,
      isConfirm: false,
    });
    const payFormRef = ref(null);

    const data = reactive({
      currentMember,
      memberList,
      payMemberVisible,
      orderNo,
      codeUrl,
      timer,
      form,
      payFormRef,
    });

    const setData = (type, value) => {
      Member().setData(type, value);
    };

    const changeSelect = (val) => {
      let arr = memberList.value.value.filter((e) => e.id === val);
      if (arr.length > 0) {
        form.value.money = arr[0].price;
        form.value.originalMoney = arr[0].price;
        form.value.level = arr[0].level;
      }
    };

    const confirmOrder = async () => {
      let param = {
        memberLevelId: form.value.memberLevelId,
        payType: form.value.payType,
      };

      if (!form.value.memberLevelId) {
        ElMessage.error("请选择会员等级");
        return;
      }
      if (!form.value.payType) {
        ElMessage.error("请选择支付方式");
        return;
      }
      let result;
      if (currentMember.value.value && currentMember.value.value.status === 1) {
        if (currentMember.value.value.level > form.value.level) {
          result = ElMessageBox.confirm(
            "您现在选择的会员等级低于已开通的会员等级，请会员期限截止后再次尝试！",
            {
              cancelButtonText: "确定",
              type: "warning",
            }
          );
          return;
        } else if (currentMember.value.value.level === form.value.level) {
          result = ElMessageBox.confirm(
            "您当前会员权益未到期，是否进行购买续费？",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        } else {
          result = ElMessageBox.confirm(
            "您现在选择的是会员升级，为保障您的权益，系统会将当前会员未使用的时间进行折现。新的会员权益购买后立即生效！",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          );
        }
      } else {
        result = new Promise((resolve) => {
          resolve();
        });
      }
      result
        .then(() => {
          form.value.isConfirm = true;
          pay(param).then((rsp) => {
            const timer = setInterval(() => {
              checkOrder();
            }, 2000);
            codeUrl.value = window.location.origin + "/" + rsp.data.url;
            setData("orderNo", rsp.data.orderNo);
            setData("timer", timer);
            form.value.originalMoney = rsp.data.originalMoney;
            form.value.offsetMoney = rsp.data.offsetMoney;
            form.value.money = rsp.data.money;
            form.value.isConfirm = true;
          });
        })
        .catch(() => {
          console.log("已取消");
        });
    };
    const checkOrder = () => {
      checkOrderStatus({ orderNo: orderNo.value.value }).then((res) => {
        if (res.data.paySuccess) {
          clearInterval(timer.value.value);
          Member().setData("money", res.data.money);
          Member().setData("payTime", res.data.payTime);
          Member().setData("payType", res.data.channel);
          form.value = {};
          codeUrl.value = "";
          Member().toPayResult();
        }
      });
    };

    return {
      ...toRefs(data),
      changeSelect,
      confirmOrder,
    };
  },
};
</script>
<style scoped>
@import url("./PayMember.css");
</style>
