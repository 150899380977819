<template>
  <BaseModular subTitle="会员权益一览表"
               title="">
    <div class="base">
      <div class="guide">
        <div class="content">
          <table class="benefits">
            <tr>
              <th class="title"
                  style="width: 150px;">平台服务</th>
              <th class="title"
                  style="width: 380px;">服务内容</th>
              <th class="title"
                  style="width: 210px;">非会员单项费用</th>
              <th class="vip"
                  v-for="item in memberList"
                  :key="item.id">
                <span>
                  <p>{{ item.name }}</p>
                  {{ getPrice(item) }}
                </span>开通
              </th>
            </tr>
            <tr v-for="obj in benefitTypeList"
                :key="obj.id">
              <th class="fontWeight">{{ obj.platform }}</th>
              <th class="fontWeight">{{ obj.content }}</th>
              <th class="fontWeight">{{ obj.singleCost }}</th>
              <td v-for="item in memberList"
                  :key="item.id">
                {{ getDiscountValue(getBenefit(item.benefits, obj.benefitType)) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="apply">
        <el-button type="primary"
                   size="large"
                   @click="toPayMember">立即开通</el-button>
      </div>
    </div>
  </BaseModular>
</template>


<script>
import { onBeforeMount, computed, reactive, toRefs } from "vue";
import BaseModular from "@/components/BaseModular/BaseModular";
import { getMemberBenefitType, getMemberLevelBenefits } from "@/api/Member/Member";
import { storeToRefs } from "pinia";
import { Member } from '@/store'

export default {
  components: { BaseModular },

  setup() {

    onBeforeMount(async () => {
      const res1 = await getMemberBenefitType();
      Member().setData('benefitTypeList', res1.data)
      const res2 = await getMemberLevelBenefits();
      Member().setData('memberList', res2.data)
    });

    const benefitTypeList = computed(() => storeToRefs(Member()).benefitTypeList);
    const memberList = computed(() => storeToRefs(Member()).memberList);

    const getBenefit = (benefits, type) => {
      return benefits.find(f => f.benefitType === type);
    };

    const getTimeUnit = (name) => {
      switch (name) {
        case "DAY":
          return "天";
        case "WEEK":
          return "周";
        case "MONTH":
          return "月";
        case "YEAR":
          return "年";
        default:
          return "";
      }
    }

    const getPrice = (member) => {
      if (JSON.stringify(member) == "{}") {
        return "";
      }
      return '（' + member.price + '元/' + (member.validTime === 1 ? '' : member.validTime) + getTimeUnit(member.validUnit) + '）'
    }

    const getDiscountValue = (benefit) => {
      if (!benefit) {
        return "";
      }
      if (benefit.onlyDisplay === 0) {
        if (benefit.discountType === 0) {
          return "不享受";
        } else if (benefit.discountType === 1) {
          if (benefit.freeNumber >= 99999) {
            return "享受不限次免费";
          } else {
            return "享受" + benefit.freeNumber + "次免费";
          }
        } else {
          return "享受" + benefit.discountValue * 10 + "折优惠";
        }
      } else {
        if (benefit.discountType === 0) {
          return "不享受";
        } else {
          return "享受";
        }
      }
    }

    const toPayMember = () => {
      if (Member()) {
        Member().openPayMember();
      }
    }

    const data = reactive({
      benefitTypeList,
      memberList
    })

    return {
      ...toRefs(data),
      getBenefit,
      getTimeUnit,
      getPrice,
      getDiscountValue,
      toPayMember
    };
  }
}
</script>

<style scoped>
@import url("./MemberBenefits.css");
</style>
