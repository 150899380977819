<template>
  <BasePage :bannerUrl="indexBannerImg" subTilte="会员权益" content="开通会员享优惠">
    <MemberBenefits />
    <PayMember />
    <PayResult />
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { onBeforeMount } from "vue";
import { mainStore } from "@/store";
import BasePage from "@/components/BasePage/BasePage.vue";
import MemberBenefits from "./components/MemberBenefits/MemberBenefits";
import PayMember from "./components/PayMember/PayMember";
import PayResult from "./components/PayResult/PayResult";


export default {
  components: { BasePage, MemberBenefits, PayMember, PayResult },
  setup() {
    onBeforeMount(async () => {
      mainStore().setData("news_ActiveType", "news");
    });
    return {
      ...imgData,
    };
  },
};
</script>

<style scoped></style>

