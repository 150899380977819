<template>
  <el-dialog v-model="payResultVisible"
             title="支付成功"
             width="800px">
    <el-row>
      <el-form size="default">
        <el-form-item label="会员等级：">
          <span>恭喜您已成为平台权益会员，可前往<el-link type="primary"
                     :href="link"
                     target="_blank">管理中心</el-link>查看您的会员权益</span>
        </el-form-item>
        <el-form-item label="支付金额：">
          <span class="price">{{ money || "0.00" }}</span>
        </el-form-item>
        <el-form-item label="支付方式：">
          <span>{{ payType }}</span>
        </el-form-item>
        <el-form-item label="支付时间：">
          <span>{{ payTime }}</span>
        </el-form-item>
      </el-form>
    </el-row>
  </el-dialog>
</template>
<script>
import { storeToRefs } from "pinia";
import { Member } from "@/store";
import { reactive, toRefs, computed } from "vue";

export default {
  setup() {
    const link = computed(() => process.env.VUE_APP_SEVERCE_PLATFORM);
    const payResultVisible = computed(
      () => storeToRefs(Member()).payResultVisible
    );
    const money = computed(() => storeToRefs(Member()).money);
    const payType = computed(() => {
      let pay = storeToRefs(Member()).payType;
      if (pay.value === "wxpay") {
        return "微信";
      } else if (pay.value === "alipay") {
        return "支付宝";
      }
      return "";
    });
    const payTime = computed(() => storeToRefs(Member()).payTime);

    const data = reactive({
      link,
      payResultVisible,
      money,
      payType,
      payTime,
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>
<style scoped>
@import url("./PayResult.css");
</style>
